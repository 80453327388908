
import "@/assets/styles/imports/history.sass"
import { Component, Vue } from 'vue-property-decorator'
import { TaskInterface } from '@/interfaces/task'
import TheHistoryFilter from '@/components/history/TheHistoryFilter.vue'
import TheHistoryTable from '@/components/history/TheHistoryTable.vue'
import Pagination from '@/components/_tools/Pagination.vue'
import api from '@/api'

@Component({
  components: {
    TheHistoryFilter,
    TheHistoryTable,
    Pagination
  }
})
export default class TheHistory extends Vue {
  tasks: Array<TaskInterface.History> = []
  loading = true
  count = 0
  pageLength = 50
  query = ''
  page = 1

  async fetchTasks (page = this.page) {
    const params = {
      searchPattern: this.query,
      skip: this.getSkipTasks(page),
      take: this.pageLength
    }
    const tasks = await api.task.getView(params)
    if (tasks) {
      this.tasks = tasks.items
      this.count = tasks.itemsCount
    } else {
      this.tasks = []
      this.count = 0
    }
    window.scrollTo(0, 0)
    this.loading = false
    this.checkEmptyHistory()
  }

  getSkipTasks (page: number): number {
    if (page < 2) return 0
    return (page - 1) * this.pageLength
  }

  checkEmptyHistory () {
    if (this.tasks.length) return
    this.$emit('empty-history')
  }

  eventRefreshSearch (query: string) {
    this.query = query
    this.fetchTasks()
  }

  async refreshPage (page: number) {
    this.page = page
    await this.fetchTasks(page)
  }

  get countPage () {
    return Math.ceil(this.count / this.pageLength)
  }

  created () {
    this.fetchTasks()
  }
}
