
import { Component, Vue } from 'vue-property-decorator'
import TheHistory from '@/components/history/TheHistory.vue'
import TheHistoryEmpty from '@/components/history/TheHistoryEmpty.vue'
import ModalDownload from '@/components/_modal/ModalDownload.vue'
import HowWorks from '@/components/HowWorks.vue'
import storage from '@/storage'
import api from '@/api'

@Component({
  components: {
    ModalDownload,
    TheHistory,
    TheHistoryEmpty,
    HowWorks
  },
  metaInfo() {
    return{
    title: this.$tc('caption'),
  }}
})

export default class HistoryPage extends Vue {
  empty = false
  showDownloadModal = false
  downloadToken = ''

  async checkOpenDownloadModal () {
    const taskId = storage.lastPaymentId
    if (!taskId) return
    storage.lastPaymentId = null
    const status = await api.payment.getTaskStatus(taskId)
    if (status !== 'Paid') return
    const token: string|null = await api.task.getDownloadLink(taskId)
    if (!token) return
    this.downloadToken = token
    this.openDownloadModal()
  }

  openDownloadModal () {
    this.showDownloadModal = true
  }

  closeDownloadModal () {
    this.showDownloadModal = false
  }

  created () {
    this.checkOpenDownloadModal()
  }
}
