
import { Component, Prop, Vue } from "vue-property-decorator";
import { TaskInterface } from "@/interfaces/task";
import History = TaskInterface.History;
import TheHistoryTableItem from "@/components/history/TheHistoryTableItem.vue";
import getCurrencySymbol from "@/additionally/getCurrencySymbol";
import api from "@/api";

@Component({
  components: {
    TheHistoryTableItem,
  },
})
export default class TheHistoryTableFirstEntry extends Vue {
  @Prop({ type: Object }) readonly task!: History;
  @Prop({ type: String }) readonly locale!: string;

  nameList = [
    "vehicleTypeName",
    "vehicleBrandName",
    "vehicleModelName",
    "vehicleEngineName",
    "vehicleEcuName",
  ];

  async downloadFile() {
    await api.firmware.downloadFile(this.task.taskDtcView.downloadToken);
  }

  get date(): string {
    const date: Date = new Date(this.task.taskCreated);
    return date.toLocaleDateString(this.locale);
  }

  get name(): string {
    const name: Array<string> = [];
    this.nameList.forEach((field) => {
      if (this.task[field]) name.push(this.task[field]);
    });
    return name.join(" / ");
  }

  get price(): string {
    return `${this.task.totalPriceSum - this.task.totalDiscountSum} ${
      this.currency
    }`;
  }

  get currency(): string {
    return getCurrencySymbol(this.task.currencyId);
  }

  get link(): string {
    return `/download/${this.task.taskDtcView.downloadToken}`;
  }

  getStatusInfo(): string {
    return this.task.taskStatusInfo.find(
      (ex: any) => ex.language === this.locale
    )?.info;
  }
}
