
import { Component, Prop, Vue } from "vue-property-decorator";
import { TaskInterface } from "@/interfaces/task";
import History = TaskInterface.History;
import TheHistoryTableFirstEntry from "@/components/history/TheHistoryTableFirstEntry.vue";
import TheHistoryTableView from "@/components/history/TheHistoryTableView.vue";
import ModalSupport from "@/components/_modal/ModalSupport.vue";

@Component({
  components: {
    TheHistoryTableFirstEntry,
    TheHistoryTableView,
    ModalSupport,
  },
})
export default class TheHistoryTable extends Vue {
  @Prop({ type: Array, default: [] }) readonly tasks!: Array<History>;

  headList = ["n", "date", "info", "status", "sum", "file"];
  isOpenedSupportModal = false;
  selectedTask = null;

  closeModal() {
    this.isOpenedSupportModal = false;
  }

  openModal(task: any) {
    this.isOpenedSupportModal = true;
    this.selectedTask = task;
  }

  alertText(task: any): string {
    return task.newTaskInfos ? task.newTaskInfos.find((ex: any) => ex.language === this.locale)?.info : '';
  }

  get locale() {
    return this.$i18n.locale === "ru" ? "ru-RU" : "en-US";
  }
}
