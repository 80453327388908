
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class TheHistoryTableItem extends Vue {
  @Prop({ type: String, default: '' }) readonly mod!: string
  @Prop({ type: String, default: '' }) readonly text!: string

  get cssClass (): string {
    return `history__item_${this.mod}`
  }
}
