
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class TheHistoryFilter extends Vue {
  search = ''
  prev = {
    start: '',
    end: '',
    search: ''
  }

  checkSearch () {
    const search = this.search
    setTimeout(() => { this.checkMatchSearch(search) }, 400)
  }

  checkMatchSearch (value: string) {
    if (value === this.prev.search) return
    if (value !== this.search) return
    this.prev.search = this.search
    this.$emit('refresh-search', value)
  }
}
