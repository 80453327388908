
import "@/assets/styles/imports/modal-support.sass";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import api from "@/api";
import getLangIdObject from "@/additionally/getLangIdObject";
import { SupportInterface } from "@/interfaces/support";

@Component
export default class ModalSupport extends Vue {
  @Prop({ default: "" }) readonly task!: any;

  responseTypes: Array<SupportInterface.SelectOption> | null = null;
  selectedValue = 0;
  selectOptions = null;
  description = "";
  isCorrectForm = false;
  isSuccess = false;

  closeModal() {
    this.$emit("close");
  }

  submitForm() {
    this.isCorrectForm = false;
    api.support
      .open(this.task.taskId, this.selectedValue, this.description)
      .then(() => {
        //this.responseTypes = response;
        this.isCorrectForm = true;
        this.isSuccess = true;
      });

    //this.closeModal();
  }

  checkForm() {
    if (this.selectedValue !== 0 && this.description) {
      this.isCorrectForm = true;
    } else {
      this.isCorrectForm = false;
    }
  }

  @Watch("selectedValue")
  onWatchSelect() {
    this.checkForm();
  }

  @Watch("description")
  onWatchDescription() {
    this.checkForm();
  }

  getType(infos: any) {
    return getLangIdObject(infos).descr;
  }

  getTypes() {
    console.log(this.responseTypes);
  }

  async mounted() {
    
    api.support.getMessageTypes().then((response: any) => {
      this.responseTypes = response;
    });
  }
}
